<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'aulasInfantis',
                  params: { gestaoDeAulaId: $route.params.gestaoDeAulaId },
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aula
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green" class="mb-5" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>Componentes Curriculares: {{ gestaoDeAula.disciplina.descricao }}</h4>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
            </h4>
            <h4>
              Turno:
              {{
                gestaoDeAula.turma.turno ? gestaoDeAula.turma.turno.descricao : "[NÃO INFORMADO]"
              }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Tipo de Aula" rules="required" v-slot="{ errors }">
              <e-label>Tipo de aula</e-label>
              <e-autocomplete
                :items="$constants.tiposDeAulas"
                :return-object="false"
                :error-messages="errors"
                v-model="aulaSelecionada.tipo_de_aula"
                @change="() => verificaTipoDaAula()"
                label="Selecione um tipo de aula"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row v-if="mostrarCampos">
          <v-col class="pt-0 pb-0" cols="4">
            <v-dialog ref="dialog" v-model="modal" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <e-label>Selecione uma data</e-label>
                <v-text-field
                  v-model="aulaSelecionada.data"
                  persistent-hint
                  :disabled="aulaSelecionada.tipo_de_aula ? false : true"
                  label="Escolha uma data"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                ></v-text-field>
              </template>
              <v-date-picker v-model="data" scrollable :allowed-dates="allowedDates">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="myCustomEvent(data)"> OK </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col class="pt-0 pb-0" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'" cols="4">
            <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
              <e-label>Selecione um horário</e-label>
              <e-autocomplete
                :disabled="aulaSelecionada.data ? false : true"
                :items="horarios"
                :item-text="(item) => item.descricao"
                :return-object="false"
                :item-value="(item) => item.id"
                :error-messages="errors"
                v-model="horarios_infantis"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>

            <!-- @change="() => pesquisaAulas()" -->
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Dia da semana</e-label>
            <v-text-field solo dense v-model="aulaSelecionada.dia_da_semana" :disabled="true">
            </v-text-field>
          </v-col>

          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Saberes e conhecimentos" rules="required" v-slot="{ errors }">
              <e-label>Saberes e conhecimentos</e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.saberes_conhecimentos"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider
              name="Estratégia Didática (Metodologia)"
              rules="required"
              v-slot="{ errors }"
            >
              <e-label>Estratégia Didática (Metodologia)</e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.metodologia"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>

          <v-col cols="12">
            <v-dialog
              v-model="dialog"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mb-3 mt-5" color="blue" dark v-bind="attrs" v-on="on">
                  INSIRA O CÓDIGO
                </v-btn>
              </template>

              <v-card>
                <v-toolbar dark color="blue">
                  <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>PESQUISA</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>

                <v-col>
                  <v-row>
                    <v-col cols="12">
                      <v-sheet color="white" elevation="1">
                        <div class="py-2 px-4" color="white">
                          <v-text-field
                            @blur="search.length === 0 ? (objetivosAprendizagem = []) : false"
                            v-model="search"
                            autofocus
                            v-on:keyup="() => procurarHabilidade()"
                            label="DIGITE O CODIGO!"
                          ></v-text-field>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6">
                      <v-sheet color="white" elevation="1" rounded>
                        <v-lazy>
                          <div class="text-start py-2 px-4 text-button blue white--text">
                            HABILIDADES ENCONTRADAS
                            <v-icon dark right> mdi-magnify </v-icon>
                          </div>
                        </v-lazy>
                        <v-divider class="theme--light"></v-divider>

                        <div class="py-2 px-4">
                          <v-skeleton-loader
                            v-if="carregandoHabilidades"
                            class="mx-auto"
                            max-width="auto"
                            type="list-item"
                          ></v-skeleton-loader>

                          <v-list-item
                            class="grey lighten-3 mb-3 pa-md-4"
                            v-for="m in objetivosAprendizagem"
                            :key="m.id"
                          >
                            <v-col cols="12" sm="10" class="d-flex flex-column justify-start">
                              [ {{ m.apelido }} ] {{ m.descricao }}
                              <v-row>
                                <v-col>
                                  <v-chip class="mt-2" color="primary">
                                    {{ m.parent ? m.parent.descricao : "" }}
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-col>

                            <v-col cols="12" sm="2" align="center">
                              <v-btn
                                @click="() => adicionarHabilidade(m)"
                                class="mx-2"
                                fab
                                dark
                                small
                                color="blue"
                              >
                                <v-icon dark> mdi-plus </v-icon>
                              </v-btn>
                            </v-col>
                          </v-list-item>
                        </div>
                      </v-sheet>
                    </v-col>
                    <v-col cols="6">
                      <v-sheet color="white" elevation="1">
                        <v-lazy>
                          <div class="text-start py-2 px-4 text-button blue white--text">
                            Habilidades selecionados
                            <v-icon dark right> mdi-checkbox-marked-circle </v-icon>
                          </div>
                        </v-lazy>

                        <v-divider class="theme--light"></v-divider>

                        <div class="py-2 px-4">
                          <p v-if="objeto_aprendizagem.length === 0">
                            Nenhuma habilidade selecionada
                          </p>

                          <v-list-item
                            class="grey lighten-3 mb-3 pa-md-4"
                            v-for="(m, index) in objeto_aprendizagem"
                            :key="m.id"
                          >
                            <v-btn
                              @click="() => deletarHabilidade(index, m)"
                              class="mx-2"
                              fab
                              dark
                              small
                              color="red"
                            >
                              <v-icon dark> mdi-delete </v-icon>
                            </v-btn>

                            <v-col cols="12" sm="10" class="d-flex flex-column justify-start">
                              [ {{ m.apelido }} ] {{ m.descricao }}
                              <v-row>
                                <v-col>
                                  <v-chip class="mt-2" color="primary">
                                    {{ m.parent ? m.parent.descricao : "" }}
                                  </v-chip>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-list-item>
                        </div>
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-col>
              </v-card>

              <v-snackbar v-model="showMessage" color="success">
                <h4>{{ mensagem }}</h4>
                <template v-slot:action="{ attrs }">
                  <v-btn color="white" text v-bind="attrs" @click="showMessage = false"> x </v-btn>
                </template>
              </v-snackbar>
            </v-dialog>

            <div></div>

            <label for=""> OBJETIVOS DE APRENDIZAGEM E DESENVOLVIMENTO </label>
            <v-card class="pa-md-4 mt-1">
              <div>
                <v-list>
                  <v-divider class="theme--light"></v-divider>

                  <v-list-item
                    class="grey lighten-3 mb-3 pa-md-4"
                    v-for="(m, index) in objeto_aprendizagem"
                    :key="m.id"
                  >
                    <v-btn
                      @click="() => deletarHabilidade(index, m)"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="red"
                    >
                      <v-icon dark> mdi-delete </v-icon>
                    </v-btn>
                    <v-col cols="12" sm="10" class="d-flex flex-column justify-start">
                      [ {{ m.apelido }} ] {{ m.descricao }}
                      <v-row>
                        <v-col>
                          <v-chip class="mt-2" color="primary">
                            {{ m.parent ? m.parent.descricao : "" }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-list-item>
                </v-list>
              </div>
            </v-card>
          </v-col>

          <v-col>
            <ValidationProvider name="Tematica" rules="required">
              <label for=""> CAMPOS DE EXPERIÊNCIA </label>
              <v-card class="pa-md-4 mt-1">
                <div>
                  <v-list>
                    <v-divider class="theme--light"></v-divider>
                    <v-list-item
                      class="grey lighten-3 mb-3 pa-md-4"
                      v-for="m in experiencia"
                      :key="m"
                    >
                      <!-- <v-btn
                        @click="() => deletarExperiencia(m)"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="red"
                      >
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn> -->
                      {{ m }}
                    </v-list-item>
                  </v-list>
                </div>
              </v-card>
            </ValidationProvider>
          </v-col>

          <v-col
            cols="12"
            v-if="horarioSelecionado || aulaSelecionada.tipo_de_aula === 'Aula Remota' || editing"
          >
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              @click="submitForm"
              >Enviar Dados</v-btn
            >

            <v-btn
              v-if="editing"
              class="ml-3"
              color="success"
              @click="dialogDuplicar.duplicar = true"
              >Duplicar Aula</v-btn
            >
            <!-- <v-btn v-if="editing" class="ml-3" color="success" @click="dialog.exportar = true"
              >Exportar Aula</v-btn -->
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
    <e-modal-duplicar-aula
      :submittingForm="submittingForm"
      :dialog="dialogDuplicar.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios.filter((item) => item !== horarioSelecionado)"
      @dialogChange="dialogDuplicar.duplicar = $event"
    >
    </e-modal-duplicar-aula>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: Number,
      require: true,
    },
  },
  async mounted() {
    await this.loadData();
    if (this.editing) {
      this.mostrarCampos = true;
      this.outrosCampos = true;
    }
  },
  data() {
    return {
      opcoes: [
        "O eu, o outro e o nós",
        "Corpo, gestos e movimentos",
        "Escuta, fala, pensamento e imaginação",
        "Traços, sons, cores e formas",
        "Espaço, tempo, quantidades, relações e transformações",
      ],
      habilidade: null,
      modalDeletarObjetivo: false,
      mensagem: "",
      carregandoHabilidades: false,
      showMessage: false,
      experiencia: [],
      objeto_aprendizagem: [],
      horarios_infantis: [],
      objetivosAprendizagem: [],
      campos_de_experiencias: [],
      diasSemana: this.$constants.diasDaSemana,
      dialog: false,
      dialogDuplicar: {
        duplicar: false,
        exportar: false,
      },
      search: "",
      data: null,
      modal: false,
      mostrarCampos: true,
      outrosCampos: false,
      submittingForm: false,
      aulaSelecionada: {
        data: null,
        dia_da_semana: "",
      },
      horarios: [],
      horarioSelecionado: [],
      form: {
        bimestre: 0,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      diasPermitidosParaCriarAula: [0, 1, 2, 3, 4, 5, 6],
      horariosSabado: [],
      horariosDoComponente: [],
    };
  },
  watch: {
    tipo_de_aula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota") {
        this.aulaSelecionada.horario_inicial = null;
        this.aulaSelecionada.horario_final = null;
        this.horarioSelecionado = [];
      }
    },
    data(value) {
      const date = new Date(value);
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(date);
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
      this.diaDaSemana();
    },
  },
  methods: {
    myCustomEvent(data) {
      this.horariosDoComponente =
        new Date(data).getDay() === 5 ? this.horariosSabado : this.horarios;
      this.$refs.dialog.save(
        (data =
          data ||
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10))
      );
    },
    async submitForm() {
      this.aulaSelecionada.horarios_infantis = this.horarios_infantis;
      this.aulaSelecionada.objeto_aprendizagem = this.objeto_aprendizagem;
      this.aulaSelecionada.e_aula_infantil = 1;
      this.aulaSelecionada.horario_inicial = this.horarioSelecionado
        ? this.horarioSelecionado.inicio
        : null;
      this.aulaSelecionada.horario_final = this.horarioSelecionado
        ? this.horarioSelecionado.final
        : null;
      this.aulaSelecionada.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;

      this.submittingForm = true;
      this.aulaSelecionada.instrutorDisciplinaTurma_id = parseInt(
        this.$route.params.gestaoDeAulaId,
        10
      );
      this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
      try {
        if (this.editing) {
          await this.$services.aulasService.atualizar(this.aulaSelecionada);
        } else {
          this.aulaSelecionada.disciplina_id = this.gestaoDeAula.disciplina_id;
          this.aulaSelecionada.turma_id = parseInt(this.gestaoDeAula.turma_id, 10);
          this.aulaSelecionada.instrutor_id = parseInt(this.gestaoDeAula.instrutor_id, 10);
          await this.$services.aulasService.criar(this.aulaSelecionada);
        }
        this.$toast.success("Aula salva com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
      this.$router.push({
        name: "aulasInfantis",
      });
    },
    async pesquisaAulas() {
      const data_aula = this.aulaSelecionada.data;
      /* eslint-disable */
      const aula_id = this.$route.params.aula_id;
      const horario_id = this.horarioSelecionado.id;
      const turma_id = this.gestaoDeAula.turma.id;
      if (!data_aula || !this.horarioSelecionado || !this.aulaSelecionada) return;

      const response = await this.$services.aulasService.pesquisarAulasEmConflitos(
        data_aula,
        horario_id,
        turma_id,
        aula_id
      );

      if (response.data.length <= 0) {
        this.mostrarCampos = true;
        this.outrosCampos = true;
      }

      if (response.data.length > 0) {
        this.aulasEmConflitos = true;
        this.outrosCampos = false;
        this.aulasConflitadas = response.data;
      }
    },
    async verificaTipoDaAula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota" || this.editing) {
        await this.getHorarios();
        this.mostrarCampos = true;
        this.outrosCampos = true;
        return;
      }
      this.mostrarCampos = true;
      this.outrosCampos = false;
    },
    async resetPage() {
      Object.assign(this.$data, this.$options.data());
    },
    async deletarHabilidade(index, m) {
      const habilidadesExperiencias = this.objeto_aprendizagem.filter((o) => {
        return o.parent.descricao === m.parent.descricao;
      });
      if (habilidadesExperiencias.length === 1) {
        this.deletarExperiencia(m.parent.descricao);
      }
      this.objeto_aprendizagem.splice(index, 1);
      this.mensagem = "Habilidade deletada com sucesso!";
      this.showMessage = true;
    },
    async deletarExperiencia(experiencia) {
      const pesquisa = experiencia.toString();
      const index = this.experiencia.findIndex((e) => e === pesquisa);
      this.experiencia.splice(index, 1);
      // const habilidades = this.objeto_aprendizagem.filter((o) => o.parent.descricao !== pesquisa);
      // this.objeto_aprendizagem = habilidades;
    },
    async adicionarHabilidade(m) {
      const habilidade = this.objeto_aprendizagem.find((o) => o.id === m.id);
      if (habilidade) {
        this.mensagem = "Habilidade já selecionada!";
        this.showMessage = true;
        return;
      }
      this.objeto_aprendizagem.push(m);
      this.mensagem = "Habilidade adicionada com sucesso!";
      this.showMessage = true;

      this.adicionarExperiencia(m.parent.descricao);
    },
    async adicionarExperiencia(experiencia) {
      const experienciaJaExiste = this.experiencia.find((e) => e === experiencia);
      if (!experienciaJaExiste) this.experiencia.push(experiencia);
    },
    async procurarHabilidade() {
      this.carregandoHabilidades = true;
      const data = await this.$services.objetivosAprendizagemService.pesquisar(this.search);
      this.objetivosAprendizagem = data.data.data;
      this.carregandoHabilidades = false;
    },
    async getHorarios () {
      const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );
      this.gestaoDeAula = response.payload.gestaoDeAula;

      if (this.gestaoDeAula.relacoesDiasHorarios) {
        this.diasPermitidosParaCriarAula = this.gestaoDeAula.relacoesDiasHorarios.map((g) => g.dia.id);

        this.horarios = this.gestaoDeAula.relacoesDiasHorarios.map((g) => g.horario);
        this.horariosSabado = this.horarios;
        this.horariosSabado = this.horariosSabado.map((h) => {
          return {
            'id': h.id,
            'turno_id': h.turno_id,
            'descricao': h.descricao.split(" - ")[0],
            'final': h.final,
            'inicio': h.inicio,
          };
        });
      }
      this.horariosDoComponente =
        new Date(this.data).getDay() === 5 ? this.horariosSabado : this.horarios;

      // this.horarios = response.payload.horarios.filter((horario) => {
      //   return horario.descricao.toLowerCase().includes("momento");
      // });
    },
    async getAula() {
      await this.getHorarios();
      const { aula_id } = this.$route.params;
      const aulaSelecionada = await this.$services.aulasService.getAula(parseInt(aula_id, 10));
      this.objeto_aprendizagem = aulaSelecionada.sistema_bncc;

      if (Array.isArray(aulaSelecionada.horarios_infantis)) {
          if (aulaSelecionada.horarios_infantis.length > 1) {
              this.horarios_infantis = aulaSelecionada.horarios_infantis[0];
          } else {
              this.horarios_infantis = null; 
          }
      } else {
          this.horarios_infantis = aulaSelecionada.horarios_infantis;
      }

      aulaSelecionada.sistema_bncc.map((item) => {
        this.experiencia.push(item.parent.descricao);
      });

      this.experiencia = this.experiencia.filter(this.getUnique);

      return aulaSelecionada;
    },
    getUnique(value, index, self) {
        return self.indexOf(value) === index;
    },
    async loadData(value) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.getHorarios();
        if (this.editing) {
          this.aulaSelecionada = await this.getAula();
          if (this.aulaSelecionada.data)
            this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    diaDaSemana() {
      var data_do_usuario = this.aulaSelecionada.data;
      var data_do_usuario_formatada = data_do_usuario.replace(/\//g, "-");
      var array1 = data_do_usuario_formatada.split("-");
      var array2 = array1.reverse();
      var data_final = new Date(array2[0], parseInt(array2[1], 10) - 1, array2[2]);
      var dia = this.diasSemana[data_final.getDay()];
      this.aulaSelecionada.dia_da_semana = dia;
    },
    allowedDates(val) {
      const array = [...this.diasPermitidosParaCriarAula];
      return array.includes(new Date(val).getDay());
    },
  },
};
</script>

<style></style>
